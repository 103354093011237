import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import CronjobInfo from '../models/CronjobInfo';
import Project from '../models/Project';
import { TIMESHEET_STATUS, Timesheet } from '../models/Timesheet';
import { Timeslot } from '../models/Timeslot';

@Injectable({
    providedIn: 'root',
})
export class TimesheetsService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    syncJira(timesheetId: string){
        return this.httpClient.get(`${environment.baseUrl}/jira/syncJira/${timesheetId}`, this.httpOptions); 
    }

    getTimesheets(date: string) {
        return this.httpClient
            .get<Timesheet[]>(`${environment.baseUrl}/timesheets${this.getCorrectRoute()}/date/${date}${this.getTokenIfExists()}`, this.httpOptions)
            .pipe(map((timesheets) => this.parseTimesheetsStatus(timesheets)));
    }

    getTimesheetsByProjectUuid(projectUuid: string) {
        return this.httpClient.get<Timesheet[]>(`${environment.baseUrl}/timesheets/project/${projectUuid}`, this.httpOptions);
    }

    addTimesheet(employeeUuid: string, date: string, hoursRequired?: number) {
        const parsedDate = new Date(date);
        return this.httpClient.post<Timesheet>(
            `${environment.baseUrl}/timesheets`,
            {
                employeeUuid: employeeUuid,
                date: parsedDate,
                hoursRequired: hoursRequired ? hoursRequired : 0,
            },
            this.httpOptions,
        );
    }

    updateTimesheet(timesheet: Timesheet) {
        const body = {
            timesheetId: timesheet.uuid,
            extraInfo: timesheet.extraInfo,
            happyScore: timesheet.happyScore,
            status: timesheet.status.key,
        };

        return this.httpClient
            .put<Timesheet>(`${environment.baseUrl}/timesheets${this.getCorrectRoute()}${this.getTokenIfExists()}`, body, this.httpOptions)
            .pipe(map((timesheet) => this.parseTimesheetStatus(timesheet)));
    }

    removeTimesheet(timesheetUuid: string) {
        return this.httpClient.delete<boolean>(`${environment.baseUrl}/timesheets/${timesheetUuid}`, this.httpOptions);
    }

    addTimeslot(timesheetUuid: string, projectUuid: string, minutes: number, pdfUrl: string, description: string) {
        return this.httpClient
            .post<Timesheet>(
                `${
                    environment.baseUrl
                }/timesheets${this.getCorrectRoute()}/timeslot/${timesheetUuid}${this.getTokenIfExists()}?projectUuid=${projectUuid}&minutes=${minutes}&pdf=${pdfUrl}&description=${description}`,
                {},
                this.httpOptions,
            )
            .pipe(map((timesheet) => this.parseTimesheetStatus(timesheet)));
    }

    updateTimeslot(timeslot: Timeslot) {
        let projectUuid = '';
        if (timeslot.project != null) {
            projectUuid = '?projectUuid=';
            projectUuid += timeslot.project.uuid;
            timeslot.project = null;
        }
        return this.httpClient
            .put<Timesheet>(
                `${environment.baseUrl}/timesheets${this.getCorrectRoute()}/timeslot${this.getTokenIfExists()}${projectUuid}`,
                timeslot,
                this.httpOptions,
            )
            .pipe(map((timesheet) => this.parseTimesheetStatus(timesheet)));
    }

    public setTimeslotToIsBilled(timeslotUuid: string) {
        return this.httpClient.put<boolean>(`${environment.baseUrl}/timesheets/timeslot/bill/${timeslotUuid}`, null, this.httpOptions);
    }

    removeTimeslot(timeslotUuid: string) {
        return this.httpClient.delete(
            `${environment.baseUrl}/timesheets${this.getCorrectRoute()}/timeslot/${timeslotUuid}${this.getTokenIfExists()}`,
            this.httpOptions,
        );
    }

    getPdf(timeslotUuid: string) {
        return this.httpClient.get<any>(
            `${environment.baseUrl}/timesheets${this.getCorrectRoute()}/retrieveFile/${timeslotUuid}${this.getTokenIfExists()}`,
            this.httpOptions,
        );
    }

    getTimesheetByUserAndDate(userId: string, date: string): Observable<Timesheet> {
        return this.httpClient
            .get<Timesheet>(`${environment.baseUrl}/timesheets${this.getCorrectRoute()}/user/${userId}/${date}${this.getTokenIfExists()}`, this.httpOptions)
            .pipe(map((timesheet) => this.parseTimesheetStatus(timesheet)));
    }

    sendReminders(selectedDate: string) {
        return this.httpClient.get<boolean>(`${environment.baseUrl}/timesheets/reminders/${selectedDate}`, this.httpOptions);
    }

    sendReminder(timesheetId: string) {
        return this.httpClient.get<boolean>(`${environment.baseUrl}/timesheets/reminder/${timesheetId}`, this.httpOptions);
    }

    addReminderRule(daysBeforeEnd: number) {
        return this.httpClient.post<CronjobInfo>(`${environment.baseUrl}/timesheets/reminders/rules/${daysBeforeEnd}`, {}, this.httpOptions);
    }

    getReminderRule() {
        return this.httpClient.get<CronjobInfo[]>(`${environment.baseUrl}/timesheets/reminders/rules`, this.httpOptions);
    }

    deleteReminderRule(cron: CronjobInfo) {
        const triggerName: string = cron.name + cron.cron;
        const group: string = cron.group;
        const params: string = '?triggerName=' + triggerName + '&groupName=' + group;
        return this.httpClient.delete<boolean>(`${environment.baseUrl}/timesheets/reminders/rules${params}`, this.httpOptions);
    }

    updateExtraInfo(extraInfo: string, timesheetUuid?: string) {
        return this.httpClient
            .put<Timesheet>(
                `${environment.baseUrl}/timesheets${this.getCorrectRoute()}/extra_info${
                    this.getTokenIfExists() != '' ? this.getTokenIfExists() : '/' + timesheetUuid
                }`,
                extraInfo,
                this.httpOptions,
            )
            .pipe(map((timesheet) => this.parseTimesheetStatus(timesheet)));
    }

    getTokenIfExists() {
        if (this.isLoggedIn()) {
            if (sessionStorage.getItem('timesheetToken')) {
                sessionStorage.removeItem('timesheetToken');
            }
        }
        return sessionStorage.getItem('timesheetToken') ? `/${sessionStorage.getItem('timesheetToken')}` : ``;
    }

    getCorrectRoute() {
        if (this.isLoggedIn()) {
            if (sessionStorage.getItem('timesheetToken')) {
                sessionStorage.removeItem('timesheetToken');
            }
        }
        return sessionStorage.getItem('timesheetToken') ? 'management' : '';
    }

    isLoggedIn(): boolean {
        return localStorage.getItem('token') != undefined && localStorage.getItem('token') != null;
    }

    parseTimesheetsStatus(timesheets: Timesheet[]): Timesheet[] {
        timesheets.forEach((timesheet) => this.parseTimesheetStatus(timesheet));
        return timesheets;
    }

    parseTimesheetStatus(timesheet: Timesheet): Timesheet {
        timesheet.status = TIMESHEET_STATUS.getStatus(timesheet.status.toString());
        return timesheet;
    }

    updateHappinessScore(timesheetUuid: string, happinessScore: number) {
        let url = `${environment.baseUrl}/timesheets/${timesheetUuid}/happiness-score`;
        if (happinessScore !== null) url += `?score=${happinessScore}`;
        return this.httpClient.put<Timesheet>(url, {}, this.httpOptions);
    }

    getTimeSheetsForProject(projectId: string) {
        return this.httpClient.get<Timesheet[]>(`${environment.baseUrl}/timesheets/project/${projectId}`, this.httpOptions);
    }

    fetchTimesheetsForEmployee(employeeId: string) {
        return this.httpClient.get<Timesheet[]>(`${environment.baseUrl}/timesheets/employee/${employeeId}`, this.httpOptions);
    }

    changeTimesheetStatus(timesheetId: string, status: TIMESHEET_STATUS) {
        return this.httpClient.patch(`${environment.baseUrl}/timesheets/${timesheetId}/update-status/${status.key}`, {}, this.httpOptions);
    }

    updateRequiredHours(timesheetId: string, hours: number) {
        return this.httpClient.patch(`${environment.baseUrl}/timesheets/${timesheetId}/required-hours/${hours}`, {}, this.httpOptions);
    }

    fetchTimesheetsByProjectIdAndYearAndMonth(projectId: string, year: number, month: number) {
        return this.httpClient.get<Timesheet[]>(`${environment.baseUrl}/timesheets/project/${projectId}?month=${month}&year=${year}`, this.httpOptions);
    }

    fetchAllProjectsProtected(employeeUuid: string) {
        return this.httpClient.get<Project[]>(
            `${environment.baseUrl}/timesheets${this.getCorrectRoute()}/all-projects-protected${
                this.getTokenIfExists() != '' ? this.getTokenIfExists() : '/' + employeeUuid
            }`,
            this.httpOptions,
        );
    }
}
